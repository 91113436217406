<template>
  <div class="form-area form-style-2">
    <div class="container">
      <div class="form-wrap">
        <div class="row">
          <div class="col-xl-7 col-lg-5">
            <Form @submit="submit">
              <div class="form-field">
                <Field type="text" name="name" placeholder="Name" class="input-field" />
                <ErrorMessage name="name" />
              </div>
              <div class="form-field">
                <Field
                  type="email"
                  name="email"
                  placeholder="Email"
                  class="input-field"
                  :rules="validateEmail"
                />
                <ErrorMessage name="email" />
              </div>
              <div class="form-field">
                <Field
                  type="text"
                  name="subject"
                  placeholder="Subject*"
                  class="input-field"
                />
                <ErrorMessage name="subject" />
              </div>
              <div class="form-field mb-0">
                <Field
                  name="message"
                  placeholder="Message"
                  class="textarea-field"
                ></Field>
                <ErrorMessage name="subject" />
              </div>
              <div class="form-btn-wrap">
                <button type="submit" value="submit" class="form-btn" name="submit">
                  Submit Now
                  <i class="icofont-long-arrow-right"></i>
                </button>
                <p class="form-messege"></p>
              </div>
            </Form>
          </div>
          <div class="col-xl-5 col-lg-7">
            <div class="contact-sidebar">
              <div class="contact-sidebar-info">
                <ul class="contact-info">
                  <li>
                    <div class="contact-icon">
                      <img src="/images/contact/icon/calling.png" alt="Contact Icon" />
                    </div>
                    <a href="tel://01-518-755-6022">(+1) 518-755-6022</a>
                  </li>
                  <li>
                    <div class="contact-icon">
                      <img src="/images/contact/icon/message.png" alt="Contact Icon" />
                    </div>
                    <a href="mailto://support@gamechanger.solutions"
                      >support@gamechanger.solutions</a
                    >
                  </li>
                  <li>
                    <div class="contact-icon">
                      <img src="/images/contact/icon/location.png" alt="Contact Icon" />
                    </div>
                    <span>288 Linden Avenue Red Hook, New York USA 12571</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backendless from "backendless";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      socials: [
        {
          link: "https://www.facebook.com",
          iconName: "icofont-facebook",
        },
        {
          link: "https://www.skype.com",
          iconName: "icofont-skype",
        },
        {
          link: "https://www.twitter.com",
          iconName: "icofont-twitter",
        },
        {
          link: "https://www.linkedin.com",
          iconName: "icofont-linkedin",
        },
      ],
    };
  },
  methods: {
    submit(values) {
      const help = {
        name: values.name,
        email: values.email,
        subject: values.subject,
        message: values.message,
      };

      Backendless.Data.of("support")
        .save(help)
        .then((savedObject) => {
          console.log("savedObject", savedObject);
          this.$router.go();
        })
        .catch(function (error) {
          console.log("an error research has occurred " + error.message);
          debugger;
        });
    },
    validateEmail(value) {
      if (!value) {
        return "This field is required";
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return "This field must be a valid email";
      }
      return true;
    },
  },
};
</script>
